import * as React from 'react'
import {
  Paper,
  Grid,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import LoadingBar from '../../components/loading/LoadingBar'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import AxiosClient from '../../utils/axios'
import ErrorMessage from '../../utils/errorMessage'

export default function ChangeStatus() {
  const navigate = useNavigate()
  const params = useParams()
  const [sendRequest, setSendRequest] = useState(false)
  const [orders, setOrders] = useState([])
  const [categories] = useState([
    {
      name: 'Buyurtma buyurtmachilarga berilgan',
      value: 1,
    },
    {
      name: 'Buyurtma yetkazildi',
      value: 2,
    },
    {
      name: 'Buyurtma kelib tushgan',
      value: 3,
    },
    {
      name: 'Buyurtma yetkazilmadi',
      value: 4,
    },
    {
      name: "Onlayn to'lov qilinish kutilmoqda",
      value: 5,
    },
  ])
  const [alert, setAlert] = useState({ state: false, message: '' })
  const [category, setCategory] = useState(1)
  useEffect(() => {
    setOrders(params.orders.split(','))
  }, [])
  const handleCategoryChange = (event) => {
    setCategory(event.target.value)
  }

  const handleSubmit = async () => {
    try {
      await AxiosClient.put('/order/status', { status: category, orders })
      navigate('/order')
    } catch (error) {
      const message = ErrorMessage(error)
      setAlert({
        state: true,
        message,
      })
      setSendRequest(false)
    }
  }

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle title={`${orders.length} ta buyurtmani o'zgartirish`} />
        </Grid>
        <Grid item xs={12}>
          <InputLabel id="category-label">Holati</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={category}
            label="Holati"
            onChange={handleCategoryChange}
          >
            {categories.map((category, index) => {
              return (
                <MenuItem key={index} value={category.value}>
                  {category.name}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Saqlash
          </Button>
        </Grid>
        <Grid item xs={12}>
          {alert.state ? (
            <HttpErrorNotification message={alert.message} />
          ) : (
            <></>
          )}
        </Grid>

        {sendRequest ? <LoadingBar /> : <></>}
        <Breadcrumb />
      </Grid>
    </Paper>
  )
}
