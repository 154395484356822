import * as React from 'react'
import { Paper, Grid, TextField, Button, Typography } from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useState } from 'react'
import AxiosClient from '../../utils/axios'
import { useNavigate } from 'react-router-dom'
import LoadingBar from '../../components/loading/LoadingBar'
import ErrorMessage from '../../utils/errorMessage'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'

export default function CreateCategory() {
  const navigate = useNavigate()
  const [sendRequest, setSendRequest] = useState(false)
  const [alert, setAlert] = useState({ state: false, message: '' })

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Kategoriya nomini yozing'),
    description: Yup.string().required('Izoh yozing'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async ({ name, description }) => {
    try {
      await AxiosClient.post('/category', { name, description })
      navigate('/category')
    } catch (error) {
      const message = ErrorMessage(error)
      setAlert({
        state: true,
        message,
      })
      setSendRequest(false)
    }
  }
  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle title="Yangi kategoriya" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="name"
            name="name"
            label="Kategoriya nomi"
            {...register('name')}
            error={errors.name ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.name?.message}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="description"
            name="description"
            label="Izoh"
            {...register('description')}
            error={errors.description ? true : false}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.description?.message}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Saqlash
          </Button>
        </Grid>
        <Grid item xs={12}>
          {alert.state ? (
            <HttpErrorNotification message={alert.message} />
          ) : (
            <></>
          )}
        </Grid>

        {sendRequest ? <LoadingBar /> : <></>}

        <Breadcrumb />
      </Grid>
    </Paper>
  )
}
