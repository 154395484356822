import * as React from 'react'
import { Paper, Grid, Button, ButtonGroup } from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import { useState, useEffect } from 'react'
import AxiosClient from '../../utils/axios'
import { useParams, useNavigate } from 'react-router-dom'
import LoadingBar from '../../components/loading/LoadingBar'
import ErrorMessage from '../../utils/errorMessage'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'

export default function DeleteLocation() {
  const nav = useNavigate()
  const params = useParams()
  const [sendRequest, setSendRequest] = useState(false)
  const [alert, setAlert] = useState({ state: false, message: '' })
  const [name, setName] = useState('')
  const [location, setLocation] = useState('')
  const cancelHandler = () => nav(`/user/${params.userId}`)
  const confirmHandler = async () => {
    try {
      await AxiosClient.delete(
        `/user/${params.userId}/location/${params.index}`,
      )
      nav(`/user/${params.userId}`)
    } catch (error) {
      const message = ErrorMessage(error)
      setAlert({
        state: true,
        message,
      })
    }
  }

  const fetch = async () => {
    const result = await AxiosClient.get(`/user/${params.userId}`)
    return result.data
  }

  useEffect(() => {
    setSendRequest(true)
    fetch()
      .then(
        (data) => {
          setName(data.firstname)
          setLocation(data.location[params.index])
        },
        (error) => {
          const message = ErrorMessage(error)
          setAlert({
            state: true,
            message,
          })
        },
      )
      .then(() => setSendRequest(false))
  }, [])

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle title="Manzilni o'chirish" />
        </Grid>
        <Grid item xs={12}>
          "{name}"ning quyidagi manzilini o'chirishni tasdiqlaysizmi?
        </Grid>
        <Grid item xs={12}>
          {location}
        </Grid>

        <Grid item xs={12}>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            sx={{ m: 0.5 }}
          >
            <Button onClick={confirmHandler}>O'chirish</Button>
            <Button onClick={cancelHandler}>Bekor qilish</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12}>
          {alert.state ? (
            <HttpErrorNotification message={alert.message} />
          ) : (
            <></>
          )}
        </Grid>

        {sendRequest ? <LoadingBar /> : <></>}
        <Breadcrumb />
      </Grid>
    </Paper>
  )
}
