import * as React from 'react'
import { Paper, Grid, Button } from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import { useState, useEffect } from 'react'
import AxiosClient from '../../utils/axios'
import { useParams } from 'react-router-dom'
import LoadingBar from '../../components/loading/LoadingBar'
import ErrorMessage from '../../utils/errorMessage'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'

export default function Order() {
  const nav = useNavigate()
  const params = useParams()
  const [alert, setAlert] = useState({ state: false, message: '' })
  const [sendRequest, setSendRequest] = useState(false)
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [order, setOrder] = useState('')
  const [time, setTime] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [total, setTotal] = useState(0)
  const [registration, setRegistration] = useState('')
  const [location, setLocation] = useState('')
  const [statusText, setStatusText] = useState('')
  const [userId, setUserId] = useState(null)

  const editHandler = () => nav(`/order/status/${params.orderId}`)
  const infoHandler = () => nav(`/user/${userId}`)

  const fetch = async () => {
    const result = await AxiosClient.get(`/order/${params.orderId}`)
    return result.data
  }

  useEffect(() => {
    setSendRequest(true)
    fetch()
      .then(
        (data) => {
          setName(data.order.firstname)
          setPhoneNumber(data.order.phone)
          setOrder(data.order.order)
          setTime(`${data.order.orderdate} - ${data.order.ordertime}`)
          setPaymentMethod(
            data.order.payment_method === 'CASH'
              ? "Yetkazib berilgandan so'ng"
              : data.order.payment_method,
          )
          setTotal(data.order.total)
          setRegistration(data.order.registration)
          setLocation(data.order.location)

          switch (Number(data.order.status)) {
            case 1:
              setStatusText('Buyurtmachilarga berilganlar')
              break
            case 2:
              setStatusText('Yetkazib berilganlar')
              break
            case 3:
              setStatusText('Yangi buyurtmalar')
              break
            case 4:
              setStatusText('Yetkazilmagan')
              break
            case 5:
              setStatusText("Onlayn to'lov qilinish kutilmoqda")
              break
            default:
          }
          if (data.user) {
            setUserId(data.user._id)
          }
        },
        (error) => {
          const message = ErrorMessage(error)
          setAlert({
            state: true,
            message,
          })
        },
      )
      .then(() => setSendRequest(false))
  }, [])

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle title="Buyurtma" />
        </Grid>
        <Grid item xs={12}>
          <b>Ism:</b> {name}{' '}
          {userId && (
            <Button onClick={infoHandler}>
              <InfoIcon color="action" />
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <b>Telefon:</b> {phoneNumber}
        </Grid>
        <Grid item xs={12}>
          <b>Buyurtma:</b> {order}
        </Grid>
        <Grid item xs={12}>
          <b>Vaqti:</b> {time}
        </Grid>
        <Grid item xs={12}>
          <b>To'lov turi:</b> {paymentMethod}
        </Grid>
        <Grid item xs={12}>
          <b>Umumiy:</b> {`${total.toLocaleString('ru-RU')}`} so'm
        </Grid>
        <Grid item xs={12}>
          <b>Registratsiya payti:</b>{' '}
          {`${new Date(registration).toLocaleString('ru-RU')}`}
        </Grid>
        <Grid item xs={12}>
          <b>Manzil:</b> {location}
        </Grid>
        <Grid item xs={12}>
          <b>Buyurtma holati:</b> {statusText}
        </Grid>

        <Grid item xs={12}>
          <Button onClick={editHandler} variant="contained">
            O'zgartirish
          </Button>
        </Grid>

        <Grid item xs={12}>
          {alert.state ? (
            <HttpErrorNotification message={alert.message} />
          ) : (
            <></>
          )}
        </Grid>

        {sendRequest ? <LoadingBar /> : <></>}
        <Breadcrumb />
      </Grid>
    </Paper>
  )
}
