import * as React from 'react'
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import AxiosClient from '../../utils/axios'
import { useEffect, useState } from 'react'
import {
  Paper,
  Grid,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import ErrorMessage from '../../utils/errorMessage'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import WindowIcon from '@mui/icons-material/Window'
import InfoIcon from '@mui/icons-material/Info'

export default function Orders() {
  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [alert, setAlert] = useState({ state: false, message: '' })
  const [status, setStatus] = useState(0)
  const [statusText, setStatusText] = useState('Barcha buyurtmalar')

  const handleStatus = (event, newStatus) => {
    setStatus(newStatus)
    switch (Number(newStatus)) {
      case 0:
        setStatusText('Barcha buyurtmalar')
        break
      case 1:
        setStatusText('Buyurtmachilarga berilganlar')
        break
      case 2:
        setStatusText('Yetkazib berilganlar')
        break
      case 3:
        setStatusText('Yangi buyurtmalar')
        break
      case 4:
        setStatusText('Yetkazilmagan')
        break
      case 5:
        setStatusText("Onlayn to'lov qilinish kutilmoqda")
        break
      default:
    }
  }

  const nav = useNavigate()
  const editHandler = (id) => nav(`/order/${id}`)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  })

  async function loadServerRows(page) {
    try {
      const result = await AxiosClient.get('/order', {
        params: { page: page + 1, status },
      })
      setTotal(result.data.total)
      return result.data.orders
    } catch (error) {
      const message = ErrorMessage(error)
      setAlert({
        state: true,
        message,
      })
      return []
    }
  }

  useEffect(() => {
    let active = true

    ;(async () => {
      setLoading(true)
      const newRows = await loadServerRows(paginationModel.page)

      if (!active) {
        return
      }

      setRows(newRows)
      setLoading(false)
    })()

    return () => {
      active = false
    }
  }, [paginationModel.page, status])

  const columns = [
    {
      field: 'number',
      headerName: '#',
      sortable: false,
      editable: false,
    },
    {
      field: 'firstname',
      headerName: 'Ismi',
      sortable: false,
      editable: false,
      flex: 0.4,
    },
    {
      field: 'registration',
      headerName: 'Vaqt',
      sortable: false,
      editable: false,
      flex: 0.4,
      valueGetter: (params) =>
        `${new Date(params.row.registration).toLocaleDateString('ru-RU')}`,
    },
    {
      field: 'status',
      headerName: 'Holat',
      sortable: false,
      editable: false,
      renderCell: (params) => {
        switch (params.row.status) {
          case 1:
            return <DirectionsCarIcon fontSize="small" />
          case 2:
            return <CheckCircleIcon fontSize="small" />
          case 3:
            return <AccessTimeIcon fontSize="small" />
          case 4:
            return <DeleteIcon fontSize="small" />
          case 5:
            return <LocalAtmIcon fontSize="small" />
          default:
            return <></>
        }
      },
    },
    {
      field: 'action',
      headerName: '',
      renderCell: (params) => {
        return (
          <Grid container justifyContent="flex-end">
            <Button onClick={editHandler.bind(this, params.id)}>
              <InfoIcon color="action" />
            </Button>
          </Grid>
        )
      },
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
    },
  ]
  const editAllHandler = () => {
    const orders = rowSelectionModel.join(',')
    nav(`/order/status/${orders}`)
  }
  return (
    <Paper sx={{ p: 1 }}>
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12}>
          <PageTitle title="Buyurtma" />
        </Grid>
        <Grid item xs={12}>
          {statusText}
        </Grid>
        <Grid item xs={10}>
          <ToggleButtonGroup
            value={status}
            exclusive
            onChange={handleStatus}
            size="small"
            color="primary"
            aria-label="Platform"
          >
            <ToggleButton value="0">
              <WindowIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="3">
              <AccessTimeIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="5">
              <LocalAtmIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="1">
              <DirectionsCarIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="2">
              <CheckCircleIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="4">
              <DeleteIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent="flex-end">
            <Button onClick={editAllHandler}>
              <EditIcon />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          pagination
          paginationModel={paginationModel}
          pageSizeOptions={[10]}
          columns={columns}
          rowCount={total}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          loading={loading}
          keepNonExistentRowsSelected
          density="compact"
          checkboxSelection
          disableRowSelectionOnClick
        />
      </div>
      {alert.state ? <HttpErrorNotification message={alert.message} /> : <></>}
      <Breadcrumb />
    </Paper>
  )
}
