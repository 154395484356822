import * as React from 'react'
import * as Yup from 'yup'
import { Paper, Grid, TextField, Button, Typography } from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState, useEffect } from 'react'
import AxiosClient from '../../utils/axios'
import { useNavigate } from 'react-router-dom'
import LoadingBar from '../../components/loading/LoadingBar'
import ErrorMessage from '../../utils/errorMessage'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'

export default function Settings() {
  const navigate = useNavigate()
  const [sendRequest, setSendRequest] = useState(false)
  const [alert, setAlert] = useState({ state: false, message: '' })
  const [settingsId, setSettingsId] = useState('')

  const validationSchema = Yup.object().shape({
    delayDays: Yup.number()
      .min(0, "Kamida 0 bo'lish kerak")
      .integer()
      .required('Buyurtma berish kunini yozing'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async ({ delayDays }) => {
    try {
      await AxiosClient.put(`/settings/${settingsId}`, {
        delayDays,
      })
      navigate('/')
    } catch (error) {
      const message = ErrorMessage(error)
      setAlert({
        state: true,
        message,
      })
      setSendRequest(false)
    }
  }

  const fetch = async () => {
    const result = await AxiosClient.get('/settings')
    return result.data
  }

  useEffect(() => {
    setSendRequest(true)
    fetch()
      .then(
        (data) => {
          setValue('delayDays', data.delay_days)
          setSettingsId(data._id)
        },
        (error) => {
          const message = ErrorMessage(error)
          setAlert({
            state: true,
            message,
          })
        },
      )
      .then(() => setSendRequest(false))
  }, [])

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle title="Sozlama" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="delayDays"
            name="delayDays"
            label="Buyurtma berish kuni"
            {...register('delayDays')}
            type="number"
            error={errors.delayDays ? true : false}
            InputLabelProps={{ shrink: true }}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.delayDays?.message}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Saqlash
          </Button>
        </Grid>
        <Grid item xs={12}>
          {alert.state ? (
            <HttpErrorNotification message={alert.message} />
          ) : (
            <></>
          )}
        </Grid>

        {sendRequest ? <LoadingBar /> : <></>}
        <Breadcrumb />
      </Grid>
    </Paper>
  )
}
