import * as React from 'react'
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useState, useEffect } from 'react'
import AxiosClient from '../../utils/axios'
import { useNavigate } from 'react-router-dom'
import LoadingBar from '../../components/loading/LoadingBar'
import ErrorMessage from '../../utils/errorMessage'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'

export default function CreateCategory() {
  const navigate = useNavigate()
  const [sendRequest, setSendRequest] = useState(false)
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])
  const [alert, setAlert] = useState({ state: false, message: '' })

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Mahsulot nomini yozing'),
    description: Yup.string(),
    price: Yup.number()
      .required('Narxni yozing')
      .min(0, "Narx manfiy qiymat bo'lmasligi kerak")
      .integer("Narx butun qiymat bo'lishi kerak")
      .default(() => 0),
    picture: Yup.string(),
    minimumOrder: Yup.number()
      .required('Minium buyurtma sonini kiriting')
      .min(1, "Kamida 1 bo'lish kerak")
      .max(20, "Maximum 20 bo'lish kerak")
      .integer('Butun qiymat kiriting'),
    maximumOrder: Yup.number()
      .required('Maximum buyurtma sonini kiriting')
      .min(1, "Kamida 1 bo'lish kerak")
      .max(20, "Maximum 20 bo'lish kerak")
      .integer('Butun qiymat kiriting'),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (body) => {
    try {
      await AxiosClient.post('/product', { ...body, category })
      navigate('/product')
    } catch (error) {
      const message = ErrorMessage(error)
      setAlert({
        state: true,
        message,
      })
      setSendRequest(false)
    }
  }

  const handleCategoryChange = (event) => {
    setCategory(event.target.value)
  }

  const fetch = async () => {
    const result = await AxiosClient.get(`/category`)
    return result.data
  }

  useEffect(() => {
    fetch().then(
      (data) => {
        setCategories(data)
        if (data.length == 0) {
          setAlert({
            state: true,
            message:
              "Kategoriya topilmadi. Mahsulot qo'shish uchun, birinchi kategoriya yarating",
          })
        } else {
          setCategory(data[0]._id)
        }
      },
      (error) => {
        const message = ErrorMessage(error)
        setAlert({
          state: true,
          message,
        })
      },
    )
  }, [])
  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle title="Yangi mahsulot" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="name"
            name="name"
            label="Mahsulot nomi"
            {...register('name')}
            error={errors.name ? true : false}
            margin="dense"
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.name?.message}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="description"
            name="description"
            label="Izoh"
            {...register('description')}
            error={errors.description ? true : false}
            margin="dense"
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.description?.message}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="price"
            name="price"
            label="Narx"
            {...register('price')}
            error={errors.price ? true : false}
            type="number"
            defaultValue="0"
            margin="dense"
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.price?.message}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="picture"
            name="picture"
            label="Rasm"
            {...register('picture')}
            error={errors.picture ? true : false}
            margin="dense"
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.picture?.message}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <InputLabel id="category-label">Kategoriya</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={category}
            label="Kategoriya"
            onChange={handleCategoryChange}
          >
            {categories.map((category) => {
              return (
                <MenuItem key={category._id} value={category._id}>
                  {category.name}
                </MenuItem>
              )
            })}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="minimumOrder"
            name="minimumOrder"
            label="Minium buyurtma qilish soni"
            {...register('minimumOrder')}
            error={errors.minimumOrder ? true : false}
            type="number"
            defaultValue="1"
            margin="dense"
            sx={{ width: '30ch' }}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.minimumOrder?.message}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="maximumOrder"
            name="maximumOrder"
            label="Maximum buyurtma qilish soni"
            {...register('maximumOrder')}
            error={errors.maximumOrder ? true : false}
            type="number"
            defaultValue="1"
            margin="dense"
            sx={{ width: '30ch' }}
          />
          <Typography variant="inherit" color="textSecondary">
            {errors.maximumOrder?.message}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Saqlash
          </Button>
        </Grid>
        <Grid item xs={12}>
          {alert.state ? (
            <HttpErrorNotification message={alert.message} />
          ) : (
            <></>
          )}
        </Grid>

        {sendRequest ? <LoadingBar /> : <></>}

        <Breadcrumb />
      </Grid>
    </Paper>
  )
}
