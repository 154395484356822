import * as React from 'react'
import { Paper, Grid, Button, List, ListItem, ButtonGroup } from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import { useState, useEffect } from 'react'
import AxiosClient from '../../utils/axios'
import { useParams } from 'react-router-dom'
import LoadingBar from '../../components/loading/LoadingBar'
import ErrorMessage from '../../utils/errorMessage'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'

export default function User() {
  const nav = useNavigate()
  const params = useParams()
  const [sendRequest, setSendRequest] = useState(false)
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [locations, setLocations] = useState([])
  const [alert, setAlert] = useState({ state: false, message: '' })

  const addHandler = () => nav(`/user/${params.userId}/location`)
  const deleteHandler = (index) =>
    nav(`/user/${params.userId}/location/${index}/delete`)
  const editHandler = (index) =>
    nav(`/user/${params.userId}/location/${index}/edit`)

  const fetch = async () => {
    const result = await AxiosClient.get(`/user/${params.userId}`)
    return result.data
  }

  useEffect(() => {
    setSendRequest(true)
    fetch()
      .then(
        (data) => {
          setName(data.firstname)
          setPhoneNumber(data.phone_number)
          setLocations(data.location)
        },
        (error) => {
          const message = ErrorMessage(error)
          setAlert({
            state: true,
            message,
          })
        },
      )
      .then(() => setSendRequest(false))
  }, [])

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageTitle title="Foydalanuvchi" />
        </Grid>
        <Grid item xs={12}>
          <b>Ism:</b> {name}
        </Grid>
        <Grid item xs={12}>
          <b>Telefon:</b> {phoneNumber}
        </Grid>
        <Grid item xs={12}>
          <b>Buyurtma manzillari</b>
        </Grid>

        <Grid item xs={12}>
          <List>
            {locations.map((location, index) => (
              <ListItem key={index} disablePadding>
                {index + 1}. {location}
                <ButtonGroup
                  variant="outlined"
                  color="primary"
                  size="small"
                  aria-label="outlined primary button group"
                  sx={{ m: 0.5 }}
                >
                  <Button onClick={deleteHandler.bind(this, index)}>
                    <ClearIcon />
                  </Button>
                  <Button onClick={editHandler.bind(this, index)}>
                    <EditIcon />
                  </Button>
                </ButtonGroup>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12}>
          <Button
            onClick={addHandler.bind(this, params.id)}
            variant="contained"
          >
            + Yangi manzil qo'shish
          </Button>
        </Grid>

        <Grid item xs={12}>
          {alert.state ? (
            <HttpErrorNotification message={alert.message} />
          ) : (
            <></>
          )}
        </Grid>

        {sendRequest ? <LoadingBar /> : <></>}
        <Breadcrumb />
      </Grid>
    </Paper>
  )
}
