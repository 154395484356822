import * as React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import AxiosClient from '../../utils/axios'
import { useEffect, useState } from 'react'
import { Paper, Grid, Button, TextField } from '@mui/material'
import PageTitle from '../../components/title/PageTitle'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import ErrorMessage from '../../utils/errorMessage'
import HttpErrorNotification from '../../components/notifications/HttpErrorNotification'

export default function Users() {
  const [rows, setRows] = useState([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [alert, setAlert] = useState({ state: false, message: '' })
  const [search, setSearch] = useState('')
  const nav = useNavigate()
  const editHandler = (id) => nav(`/user/${id}`)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  })

  async function loadServerRows(page) {
    try {
      const result = await AxiosClient.get('/user', {
        params: { page: page + 1, search },
      })
      setTotal(result.data.total)
      return result.data.users
    } catch (error) {
      const message = ErrorMessage(error)
      setAlert({
        state: true,
        message,
      })
      return []
    }
  }

  useEffect(() => {
    let active = true

    ;(async () => {
      setLoading(true)
      const newRows = await loadServerRows(paginationModel.page)

      if (!active) {
        return
      }

      setRows(newRows)
      setLoading(false)
    })()

    return () => {
      active = false
    }
  }, [paginationModel.page, search])

  const columns = [
    {
      field: 'number',
      headerName: '#',
      sortable: false,
      editable: false,
    },
    {
      field: 'firstname',
      headerName: 'Ismi',
      sortable: false,
      editable: false,
      flex: 0.4,
    },
    {
      field: 'phone_number',
      headerName: 'Raqam',
      sortable: false,
      editable: false,
      flex: 0.4,
      valueGetter: (params) => `*** - ${params.row.phone_number.slice(-4)}`,
    },
    {
      field: 'action',
      headerName: '',
      renderCell: (params) => {
        return (
          <Grid container justifyContent="flex-end">
            <Button onClick={editHandler.bind(this, params.id)}>
              <EditIcon />
            </Button>
          </Grid>
        )
      },
    },
  ]

  return (
    <Paper sx={{ p: 1 }}>
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12}>
          <PageTitle title="Foydalanuvchi" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            label="ID, nomer, ism"
            variant="outlined"
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>
      <div style={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          pagination
          paginationModel={paginationModel}
          pageSizeOptions={[10]}
          columns={columns}
          rowCount={total}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          loading={loading}
          keepNonExistentRowsSelected
          density="compact"
          disableRowSelectionOnClick
        />
      </div>
      {alert.state ? <HttpErrorNotification message={alert.message} /> : <></>}
      <Breadcrumb />
    </Paper>
  )
}
