import * as React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Login from './pages/auth/Login'
import Home from './pages/home/Home'
import DashboardLayout from './components/layouts/DashboardLayout'
import './App.css'
import PageNotFound from './pages/404/PageNotFound'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ProtectRoutes } from './utils/ProtectRoutes'
import Logout from './pages/auth/Logout'
import Categories from './pages/category/Categories'
import CreateCategory from './pages/category/CreateCategory'
import EditCategory from './pages/category/EditCategory'
import Products from './pages/product/Products'
import CreateProduct from './pages/product/CreateProduct'
import EditProduct from './pages/product/EditProduct'
import Settings from './pages/settings/Settings'
import Users from './pages/user/Users'
import User from './pages/user/User'
import AddLocation from './pages/user/AddLocation'
import DeleteLocation from './pages/user/DeleteLocation'
import EditLocation from './pages/user/EditLocation'
import Orders from './pages/order/Orders'
import Order from './pages/order/Order'
import ChangeStatus from './pages/order/ChangeStatus'

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route element={<ProtectRoutes />}>
            <Route path="/" element={<DashboardLayout />}>
              <Route index element={<Home />} />
            </Route>

            <Route path="/category" element={<DashboardLayout />}>
              <Route index element={<Categories />} />
              <Route path="create" element={<CreateCategory />} />
              <Route path=":categoryId/edit" element={<EditCategory />} />
            </Route>

            <Route path="/product" element={<DashboardLayout />}>
              <Route index element={<Products />} />
              <Route path="create" element={<CreateProduct />} />
              <Route path=":productId/edit" element={<EditProduct />} />
            </Route>

            <Route path="/user" element={<DashboardLayout />}>
              <Route index element={<Users />} />
              <Route path=":userId" element={<User />} />
              <Route path=":userId/location" element={<AddLocation />} />
              <Route
                path=":userId/location/:index/delete"
                element={<DeleteLocation />}
              />
              <Route
                path=":userId/location/:index/edit"
                element={<EditLocation />}
              />
            </Route>

            <Route path="/order" element={<DashboardLayout />}>
              <Route index element={<Orders />} />
              <Route path=":orderId" element={<Order />} />
              <Route path="status/:orders" element={<ChangeStatus />} />
            </Route>

            <Route path="/settings" element={<DashboardLayout />}>
              <Route index element={<Settings />} />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </LocalizationProvider>
  )
}
